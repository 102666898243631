// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-tsx": () => import("C:\\Users\\Prime\\Documents\\WebDev\\gatsby-casper\\src\\templates\\post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-tags-tsx": () => import("C:\\Users\\Prime\\Documents\\WebDev\\gatsby-casper\\src\\templates\\tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */),
  "component---src-templates-author-tsx": () => import("C:\\Users\\Prime\\Documents\\WebDev\\gatsby-casper\\src\\templates\\author.tsx" /* webpackChunkName: "component---src-templates-author-tsx" */),
  "component---src-pages-404-tsx": () => import("C:\\Users\\Prime\\Documents\\WebDev\\gatsby-casper\\src\\pages\\404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("C:\\Users\\Prime\\Documents\\WebDev\\gatsby-casper\\src\\pages\\about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-data-tsx": () => import("C:\\Users\\Prime\\Documents\\WebDev\\gatsby-casper\\src\\pages\\data.tsx" /* webpackChunkName: "component---src-pages-data-tsx" */),
  "component---src-pages-index-tsx": () => import("C:\\Users\\Prime\\Documents\\WebDev\\gatsby-casper\\src\\pages\\index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\Users\\Prime\\Documents\\WebDev\\gatsby-casper\\.cache\\data.json")

